import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import StyledPriceBackground from '../components/price-background';
import { fonts, colors, breakPoints } from '../theme/variables';
import HeadingPrimary from '../components/heading-primary';
import CompulsoryLessons from '../components/compulsory-lessons';
import PrimaryButton from '../components/primary-button';

const PricesRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  margin-bottom: 3em;

  @media (max-width: ${breakPoints.first}) {
    flex-direction: column;
    align-items: center;

    > *:first-child {
      margin-bottom: 2em;
    }
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid ${colors.black};
  border-radius: 3px;
  padding: 1.4rem 1.2rem 0 1.2rem;
  width: 44vw;
  min-width: 44rem;
  background-color: rgba(238, 238, 238, 0.85);
  height: 100%;

  @media (max-width: ${breakPoints.first}) {
    width: 90vw;
    min-width: initial;
  }

  @media (max-width: ${breakPoints.mobile}) {
    width: 98vw;
  }

  ${props =>
    !!props.opacity &&
    `
    background-color: rgba(238, 238, 238, ${props.opacity});
  `}
`;

const CardHeader = styled.h2`
  font-family: ${fonts.Nunito};
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  padding-bottom: 0.5em;
  border-bottom: 1px solid ${colors.black};
`;

const CardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ExamText = styled.p`
  font-family: ${fonts.sourceSansPro};
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
`;

const PriceList = styled.ul`
  padding: 1.6rem 0.75rem;

  @media (max-width: ${breakPoints.mobile}) {
    padding: 1.6rem 0;
  }

  ${props =>
    !!props.border &&
    `
    padding-bottom: 0.75em;
    border-bottom: 1px solid rgba(34, 34, 34, 0.4);
    margin-bottom: 2em;
  `}

  ${props =>
    !!props.bCategory &&
    `
    display: flex;
    flex-direction: column;
    flex: 1;
  `}
`;

const PriceListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & + & {
    margin-top: 0.75em;
    border-top: 1px solid rgba(34, 34, 34, 0.2);
    padding-top: 0.75em;
  }

  ${props =>
    !!props.bottom &&
    `
    margin-top: auto !important;
    padding-top: 0 !important;
    border: 0 !important;
    font-size: 1.2rem;

    @media (max-width: ${breakPoints.first}) {
      margin-top: 2em !important;
    }
  `}

  ${props =>
    props.aid &&
    `
    color: ${colors.accent};
  `}
`;

const PriceName = styled.span`
  font-family: ${fonts.sourceSansPro};
  font-size: 1.8rem;
  font-weight: 400;
  max-width: 24rem;
  margin-left: 0.15em;

  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.5rem;
  }

  ${props =>
    props.special &&
    `
    ::after {
      margin-top: 0.2em;
      font-size: 1.6rem;
      content: 'távoktatás esetén is';
      display: block;
      padding: 0.25em 0.5em;
      background-color: ${colors.primary};
      color: ${colors.white};
      border-radius: 3px;

      @media (max-width: ${breakPoints.mobile}) {
        font-size: 1.4rem;
      }
    }
  `}
`;

const PriceAmount = styled.span`
  font-family: ${fonts.sourceSansPro};
  font-size: 1.8rem;
  font-weight: 400;
  text-align: right;
  max-width: 11rem;
  margin-right: 0.15em;

  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.5rem;
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 3em;

  ${props => props.tablet && `
    display: none;

    @media (max-width: ${breakPoints.first}) {
      display: flex;
    }
  `}

  @media (max-width: ${breakPoints.first}) {
    margin-bottom: 2em;
  }
`;

const PriceRow = ({ prices, first, category }) => (
  <PriceList border={first} bCategory={category}>
    {prices.map(priceLine => {
      const { frontmatter, id } = priceLine;
      const { name, price } = frontmatter;
      return (
        <PriceListItem key={id} aid={!!name.includes('Állami támogatás')}>
          <PriceName special={!!name.includes('KRESZ tanfolyam')}>
            {name}
          </PriceName>
          <PriceAmount>{price}</PriceAmount>
        </PriceListItem>
      );
    })}
    {category && (
      <PriceListItem bottom={category}>
        <p>
          *Azok igényelhetik, akik a "B" kategóriás vezetői engedély
          megszerzéséhez szükséges sikeres KRESZ vizsga napján még nem töltötték
          be a 20. életévüket
        </p>
      </PriceListItem>
    )}
  </PriceList>
);

const Prices = ({ data }) => {
  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;
  const pricesA = nodes.filter(node =>
    node.fileAbsolutePath.includes('prices/A')
  );
  const pricesB = nodes.filter(node =>
    node.fileAbsolutePath.includes('prices/B')
  );
  return (
    <Layout>
      <SEO title="Árak" />
      <HeadingPrimary>Oktatási díjak</HeadingPrimary>
      <PricesRow>
        <StyledPriceBackground src="b-category.jpg">
          <Card>
            <CardHeader>"B" Kategória</CardHeader>
            <CardBody>
              <PriceRow
                first
                prices={pricesB.filter(
                  priceLine => priceLine.frontmatter.type === 'normál'
                )}
              />
              <ExamText>Hatósági vizsgadíjak</ExamText>
              <PriceRow
                prices={pricesB.filter(
                  priceLine =>
                    priceLine.frontmatter.type === 'hatósági vizsgadíj'
                )}
                category="B"
              />
            </CardBody>
          </Card>
        </StyledPriceBackground>
        <Center tablet>
          <PrimaryButton outline="true" as={Link} to="/apply">Jelentkezem</PrimaryButton>
        </Center>
        <StyledPriceBackground src="a-category.jpg">
          <Card opacity={0.9}>
            <CardHeader>"A" Kategória</CardHeader>
            <CardBody>
              <PriceRow
                first
                prices={pricesA.filter(
                  priceLine => priceLine.frontmatter.type === 'normál'
                )}
              />
              <ExamText>Hatósági vizsgadíjak</ExamText>
              <PriceRow
                prices={pricesA.filter(
                  priceLine =>
                    priceLine.frontmatter.type === 'hatósági vizsgadíj'
                )}
              />
            </CardBody>
          </Card>
        </StyledPriceBackground>
      </PricesRow>
      <Center>
        <PrimaryButton outline="true" as={Link} to="/apply">Jelentkezem</PrimaryButton>
      </Center>
      <CompulsoryLessons />
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { fileAbsolutePath: { regex: "/prices/" } }
    ) {
      nodes {
        fileAbsolutePath
        id
        frontmatter {
          order
          type
          name
          price
        }
      }
    }
  }
`;

export default Prices;
