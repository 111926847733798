import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import LessonsImage from './lessons-image';

const CompulsoryLessons = ({ data }) => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(fileAbsolutePath: { regex: "/compulsory_lessons/" }) {
          frontmatter {
            title
            src
          }
        }
      }
    `}
    render={data => {
      const { markdownRemark } = data;
      const { frontmatter } = markdownRemark;
      let { src } = frontmatter;
      src = src.replace('/assets/', '');
      return <LessonsImage src={src} />;
    }}
  />
);

export default CompulsoryLessons;
