import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

const StyledImg = styled(Img)`
  width: 56rem;
  max-width: 90vw;
  margin: auto;
  margin-bottom: 3rem;
`;

const LessonsImage = ({ src }) => (
  <StaticQuery
    query={graphql`
      query {
        allFile (filter: {absolutePath: {regex: "/static/assets/[0-9]{4}\\-[0-9]{2}\\-[0-9]{2}.+(jpg|png|gif)$/"}}) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 560) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { allFile } = data;
      const { edges } = allFile;
      const edge = edges.find(elem =>
        elem.node.childImageSharp.fluid.src.includes(src)
      );
      const imageData = edge.node.childImageSharp.fluid;
      return <StyledImg fluid={imageData} />;
    }}
  />
);

export default LessonsImage;
