import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image/withIEPolyfill';

const StyledBackgroundImage = styled(BackgroundImage)`
  border-radius: 3px;
`;

const BackgroundSection = ({ src, children }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: allFile(
          filter: { absolutePath: { regex: "/category.jpg/" } }
        ) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 560) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const { desktop } = data;
      const { edges } = desktop;
      const edge = edges.find(elem => {
        const { node } = elem;
        const { childImageSharp } = node;
        const { fluid } = childImageSharp;
        const { src: fluidSrc } = fluid;
        return fluidSrc.includes(src);
      });
      const imageData = edge.node.childImageSharp.fluid;
      return (
        <StyledBackgroundImage
          Tag="section"
          fluid={imageData}
          backgroundColor="#fff"
        >
          {children}
        </StyledBackgroundImage>
      );
    }}
  />
);

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-repeat: repeat-y;
  background-position: bottom center;
  background-size: cover;
`;

export default StyledBackgroundSection;
